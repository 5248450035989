/*скрипт для file input*/
$(function(){
    var wrapper = $( ".file_upload" ),
        inp = wrapper.find( "input" ),
        btn = wrapper.find( "#file-button" ),
        lbl = wrapper.find( "#file-placeholder" );

    btn.focus(function(){
        inp.focus()
    });
    // Crutches for the :focus style:
    inp.focus(function(){
        wrapper.addClass( "focus" );
    }).blur(function(){
        wrapper.removeClass( "focus" );
    });

    var file_api = ( window.File && window.FileReader && window.FileList && window.Blob ) ? true : false;

    inp.change(function(){
        var file_name;
        if( file_api && inp[ 0 ].files[ 0 ] )
            file_name = inp[ 0 ].files[ 0 ].name;
        else
            file_name = inp.val().replace( "C:\\fakepath\\", '' );

        if( ! file_name.length )
            return;

        if( lbl.is( ":visible" ) ){
            lbl.text( file_name );
            btn.text( "Выбрать" );
        }else
            btn.text( file_name );
    }).change();
});
$( window ).resize(function(){
    $( ".file_upload input" ).triggerHandler( "change" );
});
/*--------------------*/


$(document).ready(function() {

    //плавное появление страниц услуг
    $('.epn-container').css('opacity','0');
    $('.epn-container').animate({opacity: 1}, 1000);


    $('.header__main-menu-nav').sticky({topSpacing:0});

    vex.defaultOptions.className = 'vex-theme-os';



    $('.low-price .request-modal').click(function() {

        yaCounter36045835.reachGoal('searchsale');
        ga('send', 'pageview', '/virtual/searchsale/');

        if($('.vex-dialog-form').length > 0){
            return false;
        }

        vex.dialog.open({
            message: 'Гарантия лучшей цены',
            input: "<input name=\"price\" type=\"hidden\" value=\"yes\" /><input name=\"username\" type=\"text\" placeholder=\"Имя\" required />\n<input name=\"userphone\" type=\"text\" placeholder=\"Телефон\" required />\n<textarea name=\"usercomment\" placeholder=\"Укажите ссылку на более выгодное предложение или опишите его\" required /></textarea><div class=\"file_upload\"><div id=\"file-button\">Выбрать</div><div id=\"file-placeholder\">Прикрепить чертеж</div><input type=\"file\" id=\"sale-file\" name=\"attach-file\"></div><script>$(\"input[name*=\'phone\']\").inputmask(\"+7(999)999-99-99\");var wrapper = $( \".vex-dialog-input\").find('.file_upload'),inp = wrapper.find( \"input\" ),btn = wrapper.find( \"#file-button\" ),lbl = wrapper.find( \"#file-placeholder\" );btn.focus(function(){inp.focus()});inp.focus(function(){wrapper.addClass( \"focus\" );}).blur(function(){wrapper.removeClass( \"focus\" );});var file_api = ( window.File && window.FileReader && window.FileList && window.Blob ) ? true : false;inp.change(function(){var file_name;if( file_api && inp[ 0 ].files[ 0 ] )file_name = inp[ 0 ].files[ 0 ].name;else file_name = inp.val().replace( 'C:\\fakepath\\'', '' );if( ! file_name.length ) return; if( lbl.is( ':visible' ) ){ lbl.text( file_name ); btn.text( 'Выбрать' );}else btn.text( file_name );}).change();</script>",
            buttons: [
                $.extend({}, vex.dialog.buttons.YES, {
                    text: 'Отправить'
                }), $.extend({}, vex.dialog.buttons.NO, {
                    text: ''
                })
            ],
            callback: function(data) {
                if (data !== false) {

                    var $that = $('.vex-dialog-form'),
                        formData = new FormData($that.get(0)); // создаем новый экземпляр объекта и передаем ему нашу форму

                    $.ajax({
                        type: "POST",
                        url: "sendmodal",
                        contentType: false, //убираем форматирование данных по умолчанию
                        processData: false, //убираем преобразование строк по умолчанию
                        data: formData,
                        dataType: 'json',

                        success:  function() {
                            yaCounter36045835.reachGoal('sendsearchsale');
                            ga('send', 'pageview', '/virtual/sendsearchsale/');
                            vex.dialog.alert({ className: 'vex-theme-os vexalert', message: 'Заявка успешно отправлена!', buttons: [$.extend({}, vex.dialog.buttons.OK, {text: ''})] });
                        }
                    });
                    return false;
                }
                return false;
            }
        });
    });




    $('.request-modal').click(function() {

        if($('body.kontaktnaya-informaciya').length > 0 || $('body.novosti-i-akcii').length > 0 || $('body.ceny-na-uslugi').length > 0 || $('body.oborudovanie').length > 0 || $('body.about').length > 0 || $('body.istoriya-zavoda').length > 0){
            yaCounter36045835.reachGoal('offerpayment');
            ga('send', 'pageview', '/virtual/offerpayment/');
        } else {
            yaCounter36045835.reachGoal('callbackServ');
            ga('send', 'pageview', '/virtual/callbackServ/');
        }

        if($('.vex-dialog-form').length > 0){
            return false;
        }

        vex.dialog.open({
            message: 'Онлайн-заявка на расчет',
            input: "<input name=\"username\" type=\"text\" placeholder=\"Имя\" required />\n<input name=\"userphone\" type=\"text\" placeholder=\"Телефон\" required /><div class=\"file_upload\"><div id=\"file-button\">Выбрать</div><div id=\"file-placeholder\">Прикрепить чертеж</div><input type=\"file\" id=\"sale-file\" name=\"attach-file\"></div><script>$(\"input[name*=\'phone\']\").inputmask(\"+7(999)999-99-99\");var wrapper = $( \".vex-dialog-input\").find('.file_upload'),inp = wrapper.find( \"input\" ),btn = wrapper.find( \"#file-button\" ),lbl = wrapper.find( \"#file-placeholder\" );btn.focus(function(){inp.focus()});inp.focus(function(){wrapper.addClass( \"focus\" );}).blur(function(){wrapper.removeClass( \"focus\" );});var file_api = ( window.File && window.FileReader && window.FileList && window.Blob ) ? true : false;inp.change(function(){var file_name;if( file_api && inp[ 0 ].files[ 0 ] )file_name = inp[ 0 ].files[ 0 ].name;else file_name = inp.val().replace( 'C:\\fakepath\\'', '' );if( ! file_name.length ) return; if( lbl.is( ':visible' ) ){ lbl.text( file_name ); btn.text( 'Выбрать' );}else btn.text( file_name );}).change();</script>",
            buttons: [
                $.extend({}, vex.dialog.buttons.YES, {
                    text: 'Отправить'
                }), $.extend({}, vex.dialog.buttons.NO, {
                    text: ''
                })
            ],
            callback: function(data) {
                if (data !== false) {

                    var $that = $('.vex-dialog-form'),
                        formData = new FormData($that.get(0)); // создаем новый экземпляр объекта и передаем ему нашу форму

                    $.ajax({
                        type: "POST",
                        url: "sendmodal",
                        contentType: false, //убираем форматирование данных по умолчанию
                        processData: false, //убираем преобразование строк по умолчанию
                        data: formData,
                        dataType: 'json',

                        success:  function() {
                            if($('body.kontaktnaya-informaciya').length > 0 || $('body.novosti-i-akcii').length > 0 || $('body.ceny-na-uslugi').length > 0 || $('body.oborudovanie').length > 0 || $('body.about').length > 0 || $('body.istoriya-zavoda').length > 0){
                                yaCounter36045835.reachGoal('sendscallbackServ');
                                ga('send', 'pageview', '/virtual/sendcallbackServ/');
                            } else {
                                yaCounter36045835.reachGoal('callbackServ');
                                ga('send', 'pageview', '/virtual/callbackServ/');
                            }
                            vex.dialog.alert({ className: 'vex-theme-os vexalert', message: 'Заявка успешно отправлена!', buttons: [$.extend({}, vex.dialog.buttons.OK, {text: ''})] });
                        }
                    });
                    return false;
                }
                return false;
            }
        });
    });


    $('.callback-modal').click(function() {

        yaCounter36045835.reachGoal('callback');
        ga('send', 'pageview', '/virtual/callback/');

        if($('.vex-dialog-form').length > 0){
            return false;
        }

        vex.dialog.open({
            message: 'Заказ обратного звонка',
            input: "<input name=\"username\" type=\"text\" placeholder=\"Имя\" required />\n<input name=\"userphone\" type=\"text\" placeholder=\"Телефон\" required />\n<textarea name=\"usercomment\" placeholder=\"Комментарий\"></textarea><script>$(\"input[name*=\'phone\']\").inputmask(\"+7(999)999-99-99\");</script>",
            buttons: [
                $.extend({}, vex.dialog.buttons.YES, {
                    text: 'Отправить'
                }), $.extend({}, vex.dialog.buttons.NO, {
                    text: ''
                })
            ],
            callback: function(data) {
                if (data !== false) {

                    var $that = $('.vex-dialog-form'),
                        formData = new FormData($that.get(0)); // создаем новый экземпляр объекта и передаем ему нашу форму

                    $.ajax({
                        type: "POST",
                        url: "sendmodal",
                        contentType: false, //убираем форматирование данных по умолчанию
                        processData: false, //убираем преобразование строк по умолчанию
                        data: formData,
                        dataType: 'json',

                        success:  function(data) {
                            yaCounter36045835.reachGoal('sendcallback');
                            ga('send', 'pageview', '/virtual/sendcallback/');
                            vex.dialog.alert({ className: 'vex-theme-os vexalert', message: 'Заявка успешно отправлена!', buttons: [$.extend({}, vex.dialog.buttons.OK, {text: ''})] });
                        },
                    });
                    return false;
                }
                return false;
            }
        });
    });

    /*убираем breadcrumbs если их нет*/
    if($('.bread-crumbs ul').html() == ''){
        $('.bread-crumbs').next().remove();
        $('.bread-crumbs').remove();
    }

    /*табы на главной - переключатель*/
    $('.body-container__uslugi-tabs-container-tabs a').click(function(e) {
        e.preventDefault;
        e.stopPropagation();

        var parent = $('.body-container__uslugi-tabs-container-tabs');

        parent.find('.eachtab').each(function() {
            $(this).removeClass('active-tab');
        });

        $(this).parents('.eachtab').addClass('active-tab');

        var panels = $('.body-container__uslugi-tabs-container-panels');

        panels.find('.eachpanel').each(function() {
            $(this).removeClass('active-panel');
        });

        $('#'+$(this).attr('targ')).addClass('active-panel');
    });

    /*прокладка маршрута Яндекс-карты*/
    $('.route-ym').click(function() {
        $(this).hide();
        $('#route-button').css('display', 'inline');
        $('#route-ym-input').show();
        $('#route-ym-input').focus();
    });

    $('#route-button').click(function() {
        $('#route-button').css('display', 'none');
        $('#route-ym-input').hide();
        $('.route-ym').show();


        if($('#route-ym-input').val()){
            ymaps.route([$('#route-ym-input').val(), '55.895833, 37.519036'], {mapStateAutoApply:true}).then(
                function(route) {
                    myMap.geoObjects.add(route);
                },
                function(error) {
                    alert('Невозможно построить маршрут');
                }
            );
        }
    });


    $('#route-ym-input').keypress(function(e) {
        if(e.which == 13) {
            $('#route-button').click();
        }
    });

    $('.body-container__news-container-date').click(function() {
        if($(this).attr('state') == 'off'){
            $(this).css('background-image', 'url("/assets/images/red.png")');
            $(this).attr('state', 'on');
        } else {
            $(this).css('background-image', 'url("/assets/images/gray.png")');
            $(this).attr('state', 'off');
        }
    });

    /*переопределение стандартного submit формы "скидка на заказ"*/
    $('#sale-form, #contact-form').submit(function(e){
        e.preventDefault();

        var validation = true;

        /*валидация форм перед отправкой*/
        if($('#sale-name').length > 0){
            if(! $('#sale-name').val().match(/^[а-яА-ЯёЁa-zA-Z]{2,20}$/)){
                validation = false;
                $('#sale-name').next().css('display', 'block');
                $('#sale-name').addClass('error-input');
            } else {
                $('#sale-name').next().css('display', 'none');
                $('#sale-name').removeClass('error-input');
            }
        }

        if($('#sale-phone').length > 0){
            if(! $('#sale-phone').val().match(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{5,12}$/)){
                validation = false;
                $('#sale-phone').next().css('display', 'block');
                $('#sale-phone').addClass('error-input');
            } else {
                $('#sale-phone').next().css('display', 'none');
                $('#sale-phone').removeClass('error-input');
            }
        }

        if($('#contacts-name').length > 0){
            if(! $('#contacts-name').val().match(/^[а-яА-ЯёЁa-zA-Z]{2,20}$/)){
                validation = false;
                $('#contacts-name').next().css('display', 'block');
                $('#contacts-name').addClass('error-input');
            } else {
                $('#contacts-name').next().css('display', 'none');
                $('#contacts-name').removeClass('error-input');
            }
        }

        if($('#contacts-phone').length > 0){
            if(! $('#contacts-phone').val().match(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{5,12}$/)){
                validation = false;
                $('#contacts-phone').next().css('display', 'block');
                $('#contacts-phone').addClass('error-input');
            } else {
                $('#contacts-phone').next().css('display', 'none');
                $('#contacts-phone').removeClass('error-input');
            }
        }

        if($('#contacts-email').length > 0){
            if(! $('#contacts-email').val().match(/^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/)){
                validation = false;
                $('#contacts-email').next().css('display', 'block');
                $('#contacts-email').addClass('error-input');
            } else {
                $('#contacts-email').next().css('display', 'none');
                $('#contacts-email').removeClass('error-input');
            }
        }

        if(!validation){
            return false;
        }

        if($(this).attr('id') == 'sale-form') {
            yaCounter36045835.reachGoal('offersale');
            ga('send', 'pageview', '/virtual/offersale/');
        } else {
            yaCounter36045835.reachGoal('contactform');
            ga('send', 'pageview', '/virtual/contactform/');
        }


        var $that = $(this),
            formData = new FormData($that.get(0)); // создаем новый экземпляр объекта и передаем ему нашу форму

        $.ajax({
            type: "POST",
            url: "sendmail",
            contentType: false, //убираем форматирование данных по умолчанию
            processData: false, //убираем преобразование строк по умолчанию
            data: formData,
            dataType: 'json',

            success:  function() {
                $that.html("<div id='message'></div>");
                $('#message').html("<h2>Запрос отправлен!</h2>")
                    .append("<p>Скоро мы с вами свяжемся.</p>")
                    .hide()
                    .fadeIn(1500, function() {
                        $('#message').append("<i class=\"icon-ok\"></i>");
                    });
            },
            error: function(error){
                if(error.responseText.indexOf('Recepient')){
                    $that.html("<div id='message'></div>");
                    $('#message').html("<h2>Запрос отправлен!</h2>")
                        .append("<p>Скоро мы с вами свяжемся.</p>")
                        .hide()
                        .fadeIn(1500, function() {
                            $('#message').append("<i class=\"icon-ok\"></i>");
                        });
                }
            }
        });
        return false;
    });

    /*Увеличитель картинок*/
    $(".fancybox").fancybox();

    /*Убираем глюк слайдера на главной(застывает на середине при загрузке страницы)*/
    $('.slick-next').click();

    /*обработчик кнопки "наверх"*/
    $('.header__main-menu-nav-toup').click(function() {
        $('body, html').animate({scrollTop: 0}, 1000);
    });

    $('.fotorama').fotorama({
        width: 655,
        height: 465,
        maxwidth: '100%',
    });

    $('.body-container__uslugi-tabs-container-tabs .eachtab').click(function(e) {
        e.preventDefault;
        e.stopPropagation();
        $(this).find('a').click();
    });


    $('input[name*="phone"]').inputmask('+7(999)999-99-99');


    //перенос скриптов из <script>...</script>

    $('.low-price div').css('opacity', '0');
    $('.low-price div').animate({opacity: 1}, 3000);

    var mediaKids = $('.photo_video_container_thumbs').children();
    var mediaKidsContent = $('.photo_video_content_container').children();

    mediaKids.click(function(){

        $(this).addClass('active').siblings().removeClass('active');
        var kidIndex = $(this).index();
        mediaKidsContent.hide().eq( kidIndex ).fadeIn('slow');

    });

    $('.fullwidth-container').slick({
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        arrows: true,
        autoplay: true,
        easing: 'easeOutQuart',
        autoplaySpeed: 3000
    });

    $('.partners_main_slider').slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1
    });

    $('.director_speech').slimScroll({
        height: '380px',
        alwaysVisible: true,
        railVisible: true,
    });


    if($('.kontaktnaya-informaciya').length > 0){
        var myMap;

        ymaps.ready(function () {
            myMap = new ymaps.Map("YMapsID", {
                center: [55.895833, 37.519036],
                zoom: 16
            });

            varyag = new ymaps.Placemark([55.895576, 37.517727], {
                // Свойства.
                hintContent: 'Лобненская улица, 21'
            }, {
                // Опции.
                // Своё изображение иконки метки.
                iconImageHref: 'http://zavod-varyag.ru/assets/images/placemark1.png',
                // Размеры метки.
                iconImageSize: [50, 50],
                // Смещение левого верхнего угла иконки относительно
                // её "ножки" (точки привязки).
                iconImageOffset: [-17, -42]
            });

            myMap.geoObjects.add(varyag);


            enter1 = new ymaps.Placemark([55.894734, 37.516166], {
                // Свойства.
                hintContent: 'Въезд 1'
            }, {
                // Опции.
                // Своё изображение иконки метки.
                iconImageHref: 'http://zavod-varyag.ru/assets/images/enter1.png',
                // Размеры метки.
                iconImageSize: [63, 79],
                // Смещение левого верхнего угла иконки относительно
                // её "ножки" (точки привязки).
                iconImageOffset: [-30, -75]
            });

            myMap.geoObjects.add(enter1);


            enter2 = new ymaps.Placemark([55.896625, 37.518529], {
                // Свойства.
                hintContent: 'Въезд 2'
            }, {
                // Опции.
                // Своё изображение иконки метки.
                iconImageHref: 'http://zavod-varyag.ru/assets/images/enter2.png',
                // Размеры метки.
                iconImageSize: [63, 79],
                // Смещение левого верхнего угла иконки относительно
                // её "ножки" (точки привязки).
                iconImageOffset: [-50, -80]
            });

            myMap.geoObjects.add(enter2);


            logistic = new ymaps.Placemark([55.896432, 37.521157], {
                // Свойства.
                hintContent: 'логистический центр "Деловые линии"'
            }, {
                // Опции.
                // Своё изображение иконки метки.
                iconImageHref: 'http://zavod-varyag.ru/assets/images/logistic.png',
                // Размеры метки.
                iconImageSize: [63, 79],
                // Смещение левого верхнего угла иконки относительно
                // её "ножки" (точки привязки).
                iconImageOffset: [-20, -80]
            });

            myMap.geoObjects.add(logistic);


            // Создаем многоугольник, используя вспомогательный класс Polygon.
            var varyagPolygon = new ymaps.Polygon([
                // Указываем координаты вершин многоугольника.
                // Координаты вершин внешнего контура.
                [
                    [55.89683, 37.518164],
                    [55.894841, 37.516083],
                    [55.894684, 37.516351],
                    [55.89588, 37.519395]
                ]
            ], {
                // Описываем свойства геообъекта.
                // Содержимое балуна.
                hintContent: "ЗАО Варяг"
            }, {
                // Задаем опции геообъекта.
                // Цвет заливки.
                fillColor: '#00FF0088',
                // Ширина обводки.
                strokeWidth: 2,
                opacity:0.6
            });

            // Добавляем многоугольник на карту.
            myMap.geoObjects.add(varyagPolygon);


            // Создаем многоугольник, используя вспомогательный класс Polygon.
            var logisticPolygon = new ymaps.Polygon([
                // Указываем координаты вершин многоугольника.
                // Координаты вершин внешнего контура.
                [
                    [55.897234, 37.520964],
                    [55.896728, 37.519881],
                    [55.895667, 37.521426],
                    [55.896149, 37.522445]
                ]
            ], {
                // Описываем свойства геообъекта.
                // Содержимое балуна.
                hintContent: "логистический центр Деловые линии"
            }, {
                // Задаем опции геообъекта.
                // Цвет заливки.
                fillColor: '#00FF0088',
                // Ширина обводки.
                strokeWidth: 0,
                opacity:0.6
            });

            // Добавляем многоугольник на карту.
            myMap.geoObjects.add(logisticPolygon);



        });
    }



});